import { stickyHeader } from '../modules/sticky-header';
import { throttle, debounce } from 'lodash';
import { nodeListToArray } from '../helpers/nodeListToArray'
import { mainNavigation } from '../sfj/global/main-nav';
import { privacyAlert } from '../modules/privacyAlert';
import { Accordion } from '../modules/Accordion';
import { StickyContainer } from '../modules/sticky-container';
import { Tabs, ITabOptions } from '../modules/tabs';
import { MembershipTabs } from '../pages/membership';
import { toggleContainer } from '../modules/toggleContainer';
import { headerSearch } from '../modules/headerSearch';
import { showHidePassword } from '../modules/show-hide-password';
import { quickDonate } from '../modules/donation-amounts';
import { wordflySignup } from '../modules/wordflysignup';
(function () {

    // STICKY HEADER INIT
    let navElement = null;
    if (document.querySelector('[data-sticky-header]')) {
        navElement = new stickyHeader(document.querySelector('[data-sticky-header]') as HTMLElement);
    }

    let openMobileNavElement = document.querySelector('.nav-mobile.open');

    //SCROLL AND RESIZE EVENTS
    document.addEventListener('scroll', throttle(() => {
        if (navElement && !openMobileNavElement) {
            navElement.checkPos();
        }
    }, 10));

    window.addEventListener('resize', debounce(() => {
        if (navElement && !openMobileNavElement) {
            navElement.initPos();
        }
    }, 50));

    //Main navigation init
    let mainNavElement = null;
    if (document.querySelector('[data-main-header]')) {
        mainNavElement = new mainNavigation(document.querySelector('[data-main-header] .desktop') as HTMLElement);
    }

    // Handle Mobile Nav
    var mobileBtn = document.querySelector('.header-hamburger');
    var mobileNav = document.querySelector('.nav-mobile');
    var headerComponent = document.querySelector('header');

    if (mobileBtn) {
        mobileBtn.addEventListener('click', function () {
            if (mobileBtn.classList.contains('open')) {
                mobileBtn.classList.remove('open');
                mobileNav.classList.remove('open');
                mobileBtn.setAttribute('aria-expanded', 'false');
                mobileNav.setAttribute('aria-hidden', 'true');
                headerComponent.classList.remove('nav-open');
                document.body.classList.remove('nav-open');
                mobileNav.getElementsByTagName('a')[0].focus();
            } else {
                mobileBtn.classList.add('open');
                mobileNav.classList.add('open');
                mobileBtn.setAttribute('aria-expanded', 'true');
                mobileNav.setAttribute('aria-hidden', 'false');
                document.body.classList.remove('search-active');
                headerComponent.classList.add('nav-open');
                document.body.classList.add('nav-open');
            }
        });
    }


    /*Mobile nav: Toggle subnav items*/
    var mobileNavItemContainer = document.querySelectorAll('.nav-main-item-container');
    [].forEach.call(mobileNavItemContainer, function (item) {
        if (item) {
            var arrowBtn = item.querySelector('.nav-main-item-arrow');
            var mobileSubNav = item.nextElementSibling;

            if (arrowBtn) {
                arrowBtn.addEventListener('click', function () {
                    mobileSubNav.classList.toggle('show');
                    arrowBtn.classList.toggle('open');
                });
            }
        }
    });

    // Privacy Alert
    let privacyEl = null;
    if (document.querySelector('[data-privacy-alert]')) {
        privacyEl = new privacyAlert(document.querySelector('[data-privacy-alert]') as HTMLElement);
    }

    const stickyContainerEls = [].slice.call(document.querySelectorAll('[data-sticky-el]')) as HTMLElement[];
    stickyContainerEls.forEach(stickyEl => {
        new StickyContainer(stickyEl);
    })

    let accordions = new Accordion({
        oneOpenAtATime: true
    });


    // Toggle Containers
    let containers = nodeListToArray(document.querySelectorAll('[data-toggle-container]'));
    containers.forEach((e) => {
        new toggleContainer(e);
    })

    // Tabs
    const tabs = Array.prototype.slice.call(document.querySelectorAll('[data-tabs-container]')) as HTMLElement[];
    tabs.forEach(tabs => {
        let options: ITabOptions = {
            container: tabs
        }
        new Tabs(options);
    });

    // Membership Page
    const memberTabs = Array.prototype.slice.call(document.querySelectorAll('[data-membership-tabs]')) as HTMLElement[];
    memberTabs.forEach(tabs => {
        let options: ITabOptions = {
            container: tabs
        }
        new MembershipTabs(options);
    });


    // Quick Donate
    if (document.querySelector('[data-donation-btns]')) {
        quickDonate();
    }

    // Inits
    showHidePassword();

    // Search Redirects
    let searchContainer = document.querySelector('[data-search-container]');
    if (searchContainer) {
        new headerSearch(searchContainer);
    }

    let signupModal = document.querySelector('[data-wordfly-modal]') as HTMLElement;
    if (signupModal) {
        new wordflySignup(signupModal);
    }

})();