import * as A11yDialog from 'a11y-dialog';
export class wordflySignup {
    container: HTMLElement;
    constructor(container: HTMLElement) {
        this.container = container as HTMLElement;
        this.init();
    }

    init() {
        const modal = new A11yDialog(this.container);
        modal.show();

        modal.on('hide', () => {
            const domain = window.location.origin;
            const xhttp = new XMLHttpRequest();
            xhttp.open("GET", `${domain}/api/OnTheCorner/closeSignUpModal`, true);
            xhttp.send();
        });
     
    }
}
